import { Button, Input, Modal, Select } from "@myob/myob-widgets";
import React, { useEffect, useState } from "react";
import { updateVertical } from "../ApiService";
import { showError } from "./Message";

const EditVerticalForm = (props) => {
  const [verticalId, setVerticalId] = useState(NaN);
  const [newName, setNewName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const selectVertical = (e) => {
    const { value } = e.target;
    setVerticalId(parseInt(value, 10));
  };
  const SaveChange = async () => {
    if (!isNaN(verticalId) && newName.trim() !== "") {
      await updateVertical(verticalId, newName)
        .then((resp) => {
          if (resp.status === 200) {
            props.setSuccessMessage("Vertical changed successfully");
            props.setEditVerticalForm(false);
          }
        })
        .catch((error) => {
          setErrorMessage("Name already Exist");
        });
    } else {
      const message = isNaN(verticalId)
        ? "Please select a vertical to change"
        : "Please enter a new name";
      setErrorMessage(message);
    }
  };
  useEffect(() => {
    setErrorMessage("");
  }, [verticalId]);
  return (
    <Modal
      title="Edit vertical name"
      onCancel={() => props.setEditVerticalForm(false)}
    >
      <Modal.Body>
        {errorMessage && showError(errorMessage)}
        <Select
          name="verticalId"
          label="Vertical"
          defaultValue="placeholder"
          requiredLabel="This field is required"
          onChange={selectVertical}
        >
          <Select.Option value="placeholder" label="Please select a Vertical" />

          {props.verticals.map((vertical) => (
            <Select.Option
              value={vertical.verticalId.toString()}
              label={vertical.verticalName}
              key={vertical.verticalId}
            />
          ))}
        </Select>
        {!isNaN(verticalId) && (
          <Input
            requiredLabel="This field is required"
            type="text"
            name="newVerticalName"
            onChange={(e) => setNewName(e.target.value)}
            label="New Name"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          className="btn btn-default"
          onClick={() => props.setEditVerticalForm(false)}
        >
          Cancel
        </Button>
        <Button className="btn btn-primary" onClick={() => SaveChange()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditVerticalForm;
