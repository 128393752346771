import { Button, Input, Modal } from "@myob/myob-widgets";
import React, { useState } from "react";
import { addVertical } from "../ApiService";
import { showError } from "./Message";

const AddVerticalForm = (props) => {
  const [verticalName, setVerticalName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const addVerticalAction = () => {
    if (verticalName) {
      addVertical(verticalName)
        .then((resp) => {
          if (resp.status === 201) {
            props.setSuccessMessage("Vertical added successfully");
            props.setShowVerticalForm(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(err.response.data);
        });
    } else {
      setErrorMessage("Name cannot be empty");
    }
  };
  return (
    <Modal
      title="Add a vertical"
      onCancel={() => props.setShowVerticalForm(false)}
    >
      <Modal.Body>
        {errorMessage && showError(errorMessage)}
        <Input
          type="text"
          name="VerticalName"
          onChange={(e) => setVerticalName(e.target.value)}
          id="VerticalNameInput"
          label="Vertical name"
          requiredLabel="This field is required"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          className="btn btn-default"
          onClick={() => props.setShowVerticalForm(false)}
        >
          Cancel
        </Button>
        <Button className="btn btn-primary" onClick={() => addVerticalAction()}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddVerticalForm;
