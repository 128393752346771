import { ThemeProvider } from "@myob/myob-widgets";
import HomePage from "./pages/HomePage";
import TeamPage from "./pages/TeamPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/team/:teamId" element={<TeamPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
