import { SelectPill, SelectPillGroup } from "@myob/myob-widgets";
import React, { useEffect } from "react";
import { getAllTeams } from "../ApiService";
import { HiringStatus } from "../Types/HiringStatus";

const MultipleSelectPills = (props) => {
  const fetchTeams = async () => {
    try {
      const response = await getAllTeams();
      response.data.sort((a, b) =>
        b.lastEditTime.localeCompare(a.lastEditTime),
      );
      applyFilter(response.data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const filterBySearch = (teams) => {
    if (props.search !== "") {
      return teams.filter(
        (team) =>
          team.teamName.toLowerCase().includes(props.search.toLowerCase()) ||
          team.currentlyInRotation
            .toLowerCase()
            .includes(props.search.toLowerCase()) ||
          team.managers.toLowerCase().includes(props.search.toLowerCase()) ||
          team.keyContacts.toLowerCase().includes(props.search.toLowerCase()) ||
          team.protegeAlumni
            .toLowerCase()
            .includes(props.search.toLowerCase()) ||
          team.waitList.toLowerCase().includes(props.search.toLowerCase()),
      );
    } else {
      return teams;
    }
  };

  const filterByPillValues = (pillType, filteredTeams, pillTypeInString) => {
    if (!Object.values(pillType).every((v) => v === false)) {
      props.setParams(pillTypeInString, JSON.stringify(pillType));

      return filteredTeams.filter((team) =>
        pillTypeInString === "hiringStatus"
          ? pillType[Object.keys(HiringStatus)[team.hiringStatus]] === true
          : pillType[team.verticalId] === true,
      );
    } else {
      props.setParams(pillTypeInString, "");
    }
    return filteredTeams;
  };
  const applyFilter = (teams) => {
    if (
      Object.values(props.selectedVertical).every((v) => v === false) &&
      Object.values(props.selectedHiringStatus).every((v) => v === false) &&
      props.search === ""
    ) {
      props.setTeams(teams);
      props.setParams("hiringStatus", "");
      props.setParams("vertical", "");
    } else {
      let filteredTeams = filterBySearch(teams);
      filteredTeams = filterByPillValues(
        props.selectedHiringStatus,
        filteredTeams,
        "hiringStatus",
      );
      filteredTeams = filterByPillValues(
        props.selectedVertical,
        filteredTeams,
        "vertical",
      );
      props.setTeams(filteredTeams);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [
    props.selectedHiringStatus,
    props.selectedVertical,
    props.search,
    props.showTeamForm,
  ]);

  const getSelectPills = (setSelected, selected, value, name, nameId) => (
    <SelectPill
      value={value.toString()}
      label={name}
      name={name}
      key={value}
      onChange={() =>
        setSelected((selectedValues) => ({
          ...selectedValues,
          [nameId ? nameId : name]: !selectedValues[nameId ? nameId : name],
        }))
      }
      checked={selected[nameId ? nameId : name]}
    />
  );

  return (
    <div>
      <SelectPillGroup label={props.label} selectionType="multi">
        {props.label === "Hiring Status"
          ? props.values.map((name, value) => {
              return getSelectPills(
                props.setSelectedHiringStatus,
                props.selectedHiringStatus,
                value,
                name,
                null,
              );
            })
          : props.values.map((name, value) => {
              return getSelectPills(
                props.setSelectedVertical,
                props.selectedVertical,
                value,
                name.verticalName,
                name.verticalId,
              );
            })}
      </SelectPillGroup>
      <br />
    </div>
  );
};

export default MultipleSelectPills;
