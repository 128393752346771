import { Alert } from "@myob/myob-widgets";
import React from "react";

const showError = (errorMessage) => {
  return <Alert tone="danger">{errorMessage}</Alert>;
};

const showSuccess = (message, setSuccessMessage) => {
  return (
    <Alert
      tone="success"
      //dismissAfter={5000}
      onDismiss={() => setSuccessMessage(false)}
    >
      {message}
    </Alert>
  );
};
export { showError, showSuccess };
