import { Button, Input, Modal, Select } from "@myob/myob-widgets";
import React, { useEffect, useState } from "react";
import { deleteVertical } from "../ApiService";
import { showError } from "./Message";

const DeleteVerticalForm = (props) => {
  const [verticalId, setVerticalId] = useState(NaN);
  const [verifyName, setVerifyName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteVerticalForm, setDeleteVerticalForm] = React.useState(false);

  const selectVertical = (e) => {
    const { value } = e.target;
    setVerticalId(parseInt(value, 10));
  };
  useEffect(() => {
    setVerticalId(NaN);
    setErrorMessage("");
    setVerifyName("");
  }, [deleteVerticalForm]);
  useEffect(() => {
    setErrorMessage("");
  }, [verticalId]);
  const deleteAction = async () => {
    const verticalToDelete = props.verticals.find(
      (vertical) => vertical.verticalId === verticalId,
    );
    if (verticalToDelete && verticalToDelete.verticalName === verifyName) {
      await deleteVertical(verticalId)
        .then((resp) => {
          if (resp.status === 200) {
            props.setSuccessMessage("Vertical deleted successfully");
            props.reload(true);
            setDeleteVerticalForm(false);
          }
        })
        .catch((error) => {
          setErrorMessage(error.response.data);
        });
    } else {
      const message = isNaN(verticalId)
        ? "Please select a vertical to delete"
        : "Please double check if names are matching";
      setErrorMessage(message);
    }
  };
  const DeleteForm = (
    <Modal
      title="Delete vertical"
      onCancel={() => setDeleteVerticalForm(false)}
    >
      <Modal.Body>
        {errorMessage && showError(errorMessage)}
        <Select
          name="verticalId"
          label="Vertical"
          defaultValue="placeholder"
          requiredLabel="This field is required"
          onChange={selectVertical}
        >
          <Select.Option value="placeholder" label="Please select a Vertical" />

          {props.verticals.map((vertical) => (
            <Select.Option
              value={vertical.verticalId.toString()}
              label={vertical.verticalName}
              key={vertical.verticalId}
            />
          ))}
        </Select>
        {!isNaN(verticalId) && (
          <Input
            requiredLabel="This field is required"
            type="text"
            name="newVerticalName"
            onChange={(e) => setVerifyName(e.target.value)}
            label="Type in the vertical name to delete"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          className="btn btn-default"
          onClick={() => setDeleteVerticalForm(false)}
        >
          Cancel
        </Button>
        <Button tone={"danger"} onClick={() => deleteAction()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <div>
      <Button tone={"danger"} onClick={() => setDeleteVerticalForm(true)}>
        Delete Vertical
      </Button>
      {deleteVerticalForm && DeleteForm}
    </div>
  );
};
export default DeleteVerticalForm;
