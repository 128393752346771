import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Columns,
  FilterBar,
  PageHead,
  Search,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import TeamCard from "../Components/TeamCard";
import { getAllVerticals } from "../ApiService";
import AddVerticalForm from "../Components/AddVerticalForm";
import TeamForm from "../Components/TeamForm";
import { HiringStatus } from "../Types/HiringStatus";
import EditVerticalForm from "../Components/EditVerticalForm";
import DeleteVerticalForm from "../Components/DeleteVerticalForm";
import { useLocation } from "react-router-dom";
import { showSuccess } from "../Components/Message";
import MultipleSelectPills from "../Components/MultipleSelectPills";

const HomePage = () => {
  const [teams, setTeams] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [showVerticalForm, setShowVerticalForm] = React.useState(false);
  const [showTeamForm, setShowTeamForm] = React.useState(false);
  const [editVerticalForm, setEditVerticalForm] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const getFilterValuesFromUrl = (filterType) => {
    const params = new URLSearchParams(window.location.search);
    if (filterType === "SearchName") {
      return params.get(filterType) ? params.get(filterType) : "";
    } else if (filterType === "hiringStatus") {
      return params.get(filterType) ? JSON.parse(params.get(filterType)) : {};
    } else if (filterType === "vertical") {
      return params.get(filterType) ? JSON.parse(params.get(filterType)) : {};
    }
  };

  const [selectedHiringStatus, setSelectedHiringStatus] = React.useState(
    getFilterValuesFromUrl("hiringStatus"),
  );
  const [selectedVertical, setSelectedVertical] = React.useState(
    getFilterValuesFromUrl("vertical"),
  );
  const [search, setSearch] = React.useState(
    getFilterValuesFromUrl("SearchName"),
  );

  const fetchVerticals = async () => {
    try {
      const response = await getAllVerticals();
      setVerticals(response.data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const setFilterValues = async (e) => {
    if (e.target.name === "SearchName") {
      setSearch(e.target.value);
    }
    setParams(e.target.name, e.target.value);
  };
  const setParams = (name, value) => {
    const params = new URLSearchParams(window.location.search);
    if (value) {
      params.set(name, value);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`,
      );
    } else {
      params.delete(name);
      window.history.replaceState(
        {},
        "",
        params.size !== 0
          ? `${window.location.pathname}?${params}`
          : `${window.location.pathname}`,
      );
    }
  };

  const location = useLocation();

  useEffect(() => {
    getFilterValuesFromUrl();
    fetchVerticals();

    setReload(false);
    location.state !== null
      ? setSuccessMessage(location.state.message)
      : void 0;
  }, [showVerticalForm, editVerticalForm, reload, successMessage]);

  const PageHeader = (
    <PageHead title="Rotation Teams">
      <DeleteVerticalForm
        verticals={verticals}
        reload={setReload}
        setSuccessMessage={setSuccessMessage}
      ></DeleteVerticalForm>
      <Button type="secondary" onClick={() => setEditVerticalForm(true)}>
        Edit Vertical
      </Button>
      {editVerticalForm && (
        <EditVerticalForm
          verticals={verticals}
          setEditVerticalForm={setEditVerticalForm}
          setSuccessMessage={setSuccessMessage}
        ></EditVerticalForm>
      )}
      <Button onClick={() => setShowVerticalForm(true)}>Add Vertical</Button>
      {showVerticalForm && (
        <AddVerticalForm
          setShowVerticalForm={setShowVerticalForm}
          setSuccessMessage={setSuccessMessage}
        ></AddVerticalForm>
      )}
      <Button onClick={() => setShowTeamForm(true)}>Add Team</Button>
      {showTeamForm && (
        <TeamForm
          setShowTeamForm={setShowTeamForm}
          setSuccessMessage={setSuccessMessage}
          verticals={verticals}
          operation={"Add"}
        ></TeamForm>
      )}
    </PageHead>
  );

  const TeamFilterBar = (
    <Card>
      <FilterBar
        onReset={() => window.location.replace(window.location.pathname)}
      >
        <Search
          onChange={setFilterValues}
          placeholder="Search teams/people..."
          label="Name"
          name="SearchName"
          value={search}
        />
        <div style={{ width: "120px" }}>
          <MultipleSelectPills
            label={"Hiring Status"}
            values={Object.keys(HiringStatus)}
            setTeams={setTeams}
            selectedVertical={selectedVertical}
            selectedHiringStatus={selectedHiringStatus}
            setSelectedVertical={setSelectedVertical}
            setSelectedHiringStatus={setSelectedHiringStatus}
            showTeamForm={showTeamForm}
            search={search}
            setParams={setParams}
          ></MultipleSelectPills>
        </div>
        <div style={{ width: "680px" }}>
          <MultipleSelectPills
            label={"Verticals"}
            values={verticals}
            setTeams={setTeams}
            selectedVertical={selectedVertical}
            selectedHiringStatus={selectedHiringStatus}
            setSelectedVertical={setSelectedVertical}
            setSelectedHiringStatus={setSelectedHiringStatus}
            showTeamForm={showTeamForm}
            search={search}
            setParams={setParams}
          ></MultipleSelectPills>
        </div>
      </FilterBar>
    </Card>
  );

  const TableBody = (
    <Table hasActions>
      <Table.Body>
        <Columns type="three" alignY={"center"}>
          {teams.map((team) => (
            <TeamCard
              team={team}
              key={team.teamId}
              edit={editVerticalForm}
              from={window.location.href}
            />
          ))}
        </Columns>
      </Table.Body>
    </Table>
  );

  return (
    <div>
      <StandardTemplate
        pageHead={PageHeader}
        filterBar={TeamFilterBar}
        alert={
          successMessage ? showSuccess(successMessage, setSuccessMessage) : ""
        }
      >
        {TableBody}
      </StandardTemplate>
    </div>
  );
};

export default HomePage;
