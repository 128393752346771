import { Button, Input, Modal, Text } from "@myob/myob-widgets";
import React, { useEffect, useState } from "react";
import { deleteTeam } from "../ApiService";
import { showError } from "./Message";
import { useNavigate } from "react-router-dom";

const DeleteTeam = (props) => {
  const [verifyName, setVerifyName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteTeamForm, setDeleteTeamForm] = React.useState(false);
  const nav = useNavigate();

  useEffect(() => {
    setErrorMessage("");
    setVerifyName("");
  }, [deleteTeamForm]);
  const deleteAction = async () => {
    if (props.team.teamName === verifyName) {
      await deleteTeam(props.team.teamId)
        .then((resp) => {
          if (resp.status === 200) {
            setDeleteTeamForm(false);
            nav("/", { state: { message: "Team successfully deleted" } });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrorMessage("Please double check name is correct");
    }
  };
  const DeleteForm = (
    <Modal title="Delete Team" onCancel={() => setDeleteTeamForm(false)}>
      <Modal.Body>
        {errorMessage && showError(errorMessage)}
        <Text>
          You are about to delete this team: <b>{props.team.teamName}</b>
        </Text>
        <Input
          requiredLabel="This field is required"
          type="text"
          name="newTeamName"
          onChange={(e) => setVerifyName(e.target.value)}
          label="Type in the team name to delete"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          className="btn btn-default"
          onClick={() => setDeleteTeamForm(false)}
        >
          Cancel
        </Button>
        <Button tone={"danger"} onClick={() => deleteAction()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <div>
      <Button tone={"danger"} onClick={() => setDeleteTeamForm(true)}>
        Delete Team
      </Button>
      {deleteTeamForm && DeleteForm}
    </div>
  );
};
export default DeleteTeam;
