import { Link, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Columns,
  Heading,
  Label,
  StandardTemplate,
  Text,
} from "@myob/myob-widgets";
import React, { useEffect, useState } from "react";
import { HiringStatus } from "../Types/HiringStatus";
import "./Pages.css";
import TeamForm from "../Components/TeamForm";
import { getAllVerticals, getTeamById } from "../ApiService";
import DeleteTeam from "../Components/DeleteTeam";
import { Parser } from "html-to-react";
import { showSuccess } from "../Components/Message";

const TeamPage = () => {
  const { teamId } = useParams();
  const [editTeamForm, setEditTeamForm] = React.useState(false);
  const [verticals, setVerticals] = useState([]);
  const [team, setTeam] = useState({});
  const [verticalName, setVerticalName] = useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  //const url = useState(useLocation)
  const { state } = useLocation();
  const fetchVerticals = async () => {
    try {
      await getAllVerticals().then((resp) => {
        team.teamId &&
          setVerticalName(
            resp.data.find(
              (vertical) => vertical.verticalId === team.verticalId,
            ).verticalName,
          );
        setVerticals(resp.data);
      });
    } catch (error) {
      console.error("Error", error);
    }
  };
  const fetchTeam = async () => {
    try {
      await getTeamById(teamId).then((resp) => {
        setTeam(resp.data);
      });
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    fetchTeam();
    fetchVerticals();
  }, [editTeamForm, team.teamId, team.verticalId]);

  const PageHeader = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Heading level={"1"}>
        <Link to={state ? state.from : "../"}>Home</Link>
      </Heading>
      <div style={{ display: "flex", columnGap: "10px" }}>
        <Label type="boxed" style={{ height: "35px" }}>
          Last edit time: <br />
          {new Date(team.lastEditTime).toLocaleDateString()}
        </Label>
        <DeleteTeam team={team}></DeleteTeam>
        <Button
          style={{ height: "32px" }}
          onClick={() => setEditTeamForm(true)}
        >
          Update Team
        </Button>
        {editTeamForm && (
          <TeamForm
            setShowTeamForm={setEditTeamForm}
            setSuccessMessage={setSuccessMessage}
            verticals={verticals}
            team={team}
            operation={"Edit"}
          ></TeamForm>
        )}
      </div>
    </div>
  );

  const HeaderBox = (title, value) => {
    return (
      <Box className={"column-item"}>
        <Heading level="2" marginBottom={"clear"} textAlign={"center"}>
          {title}
        </Heading>
        {title === "Hiring Status:" ? (
          <div style={{ textAlign: "center" }}>
            <Label
              type="boxed"
              color={value === 0 ? "red" : value === 1 ? "green" : "orange"}
            >
              {Object.keys(HiringStatus).find(
                (key) => HiringStatus[key] === value,
              )}
            </Label>
          </div>
        ) : (
          <Text fontSize="xl" textAlign={"center"}>
            {value}
          </Text>
        )}
      </Box>
    );
  };
  const SeparateList = (title, list) => {
    const separatedList =
      list != null && list !== "" ? list.split("!!!SpecialSeparator!!!") : [];
    return (
      <Box>
        <Heading level="2" marginBottom={"clear"} textAlign={"center"}>
          {title}
        </Heading>

        <div style={{ textAlign: "center", padding: "5px" }}>
          {separatedList.map((name, index) => (
            // <Pill label={name} key={index} />
            <div className={"pillForTeamPage"} key={index}>
              {name}
            </div>
          ))}
        </div>
      </Box>
    );
  };

  const TableBody = (
    <div>
      <Box className={"five-columns"} style={{ paddingBottom: "20px" }}>
        {HeaderBox("Team Name:", team.teamName)}
        {HeaderBox("Vertical:", verticalName)}
        {HeaderBox("Hiring Status:", team.hiringStatus)}
        {HeaderBox("Hiring Message:", team.hiringStatusMessage)}
        {HeaderBox("Rotation Length:", team.rotationLength)}
      </Box>
      <Columns
        type={"three"}
        style={{
          paddingBottom: "30px",
          paddingLeft: "2%",
          paddingRight: "4%",
        }}
      >
        {SeparateList("Managers:", team.managers)}
        {SeparateList("Key Contacts:", team.keyContacts)}
        {SeparateList("Protege Alumni:", team.protegeAlumni)}
      </Columns>
      <br />
      <Box
        style={{
          display: "flex",
          paddingBottom: "30px",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <Box
          style={{
            flex: "8",
          }}
        >
          <Heading level="2" marginBottom={"clear"} textAlign={"center"}>
            Team Details:
          </Heading>
          <div
            style={{
              paddingRight: "10%",
            }}
          >
            {Parser().parse(`<div class="noMargin">${team.teamDetails}</div>`)}
          </div>
        </Box>

        <Box
          style={{
            flex: "4",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          {SeparateList("Tech Details:", team.techDetails)}
          {team.teamDetails &&
            team.teamDetails.length > 700 && [
              <div key={"currentlyInRotation"}>
                {SeparateList(
                  "Currently in Rotation:",
                  team.currentlyInRotation,
                )}
              </div>,
              <div key={"waitList"}>
                {SeparateList("Wait List:", team.waitList)}
              </div>,
            ]}
        </Box>
      </Box>
      {(!team.teamDetails || team.teamDetails.length <= 700) && (
        <Columns
          type={"two"}
          style={{
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "20px",
          }}
        >
          {SeparateList("Currently in Rotation:", team.currentlyInRotation)}
          {SeparateList("Wait List:", team.waitList)}
        </Columns>
      )}
    </div>
  );
  return (
    <StandardTemplate
      pageHead={PageHeader}
      alert={
        successMessage ? showSuccess(successMessage, setSuccessMessage) : ""
      }
    >
      {TableBody}
    </StandardTemplate>
  );
};

export default TeamPage;
