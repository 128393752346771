import React, { useEffect, useState } from "react";
import { Card, Label, PageHead, Badge, Columns } from "@myob/myob-widgets";
import { HiringStatus } from "../Types/HiringStatus";
import { getVertical } from "../ApiService";
import { Link } from "react-router-dom";
import { Parser } from "html-to-react";
import "./Components.css";

const TeamCard = (props) => {
  const [verticalName, setVerticalName] = useState("");
  const getVerticalName = async () => {
    try {
      const response = await getVertical(props.team.verticalId);
      setVerticalName(response.data.verticalName);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    getVerticalName();
  }, [props.edit, props.team.verticalId]);
  const GetList = (list, listType) => {
    const protegesInRotation =
      list != null && list !== "" ? list.split("!!!SpecialSeparator!!!") : [];

    return (
      <h3
        style={{
          fontWeight: "normal",
          marginBottom: "0px",
          textAlign: "center",
        }}
      >
        {listType === "rotation" ? "Currently in rotation:" : "Wait List:"}
        <div>
          {protegesInRotation.map((protege, index) => (
            <div className={"namePill"} key={index}>
              {protege}
            </div>
          ))}
        </div>
      </h3>
    );
  };
  const CardHeader = () => (
    <div>
      <PageHead level={1} title={props.team.teamName}>
        <Label
          type="boxed"
          color={
            props.team.hiringStatus === 0
              ? "red"
              : props.team.hiringStatus === 1
              ? "green"
              : "orange"
          }
        >
          {Object.keys(HiringStatus).find(
            (key) => HiringStatus[key] === props.team.hiringStatus,
          )}
        </Label>
      </PageHead>
      <Badge className={"badgeWidth"} tone="info">
        {verticalName}
      </Badge>

      <div style={{ float: "right", paddingTop: "1px" }}>
        <Badge tone="brand" className={"badgeWidth"}>
          {props.team.rotationLength}
        </Badge>
      </div>

      <br />
      <Badge tone="neutral">
        {new Date(props.team.lastEditTime).toLocaleDateString()}
      </Badge>
    </div>
  );

  const CardBody = () => (
    <div
      style={{
        margin: "0 auto",
        color: "initial",
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {Parser().parse(`<div class="noMargin">${props.team.teamDetails}</div>`)}
    </div>
  );

  const CardFooter = () => (
    <Columns>
      {GetList(props.team.currentlyInRotation, "rotation")}

      {GetList(props.team.waitList, "waitList")}
    </Columns>
  );

  return (
    <div
      className={"homePageCard"}
      style={{
        marginBottom: "20px",
      }}
    >
      <Link
        to={`/team/${props.team.teamId}`}
        style={{ textDecoration: "none" }}
        key={props.team.teamId}
        state={{ from: props.from }}
      >
        <Card
          style={{ fontWeight: "normal" }}
          header={<Card.Header child={<CardHeader />} />}
          body={<Card.Body child={<CardBody />} />}
          footer={<Card.Footer child={<CardFooter />} />}
        />
      </Link>
    </div>
  );
};

export default TeamCard;
