import axios from "axios";

// Actual Path
const apiPath = "https://team-rotation.svc.platform.myobdev.com";

// Local Path
// const apiPath = "http://localhost:8080";

function getAllTeams() {
  return axios.get(`${apiPath}/team`);
}

function getAllVerticals() {
  return axios.get(`${apiPath}/vertical`);
}

function addVertical(name) {
  return axios.post(`${apiPath}/vertical`, { verticalName: name });
}

function addTeam(team) {
  return axios.post(`${apiPath}/team`, team);
}

function getVertical(id) {
  return axios.get(`${apiPath}/vertical/${id}`);
}
function getTeamById(id) {
  return axios.get(`${apiPath}/team/${id}`);
}

function updateVertical(id, name) {
  return axios.put(`${apiPath}/vertical/${id}`, { verticalName: name });
}

function updateTeam(id, team) {
  //check id.
  delete team.teamId;
  return axios.put(`${apiPath}/team/${id}`, team);
}

function deleteVertical(id) {
  return axios.delete(`${apiPath}/vertical/${id}`);
}

function deleteTeam(id) {
  return axios.delete(`${apiPath}/team/${id}`);
}

export {
  getAllTeams,
  getAllVerticals,
  addVertical,
  addTeam,
  getVertical,
  updateVertical,
  updateTeam,
  getTeamById,
  deleteVertical,
  deleteTeam,
};
