import { useEffect, useState } from "react";
import { BulkAdd, Input, Label } from "@myob/myob-widgets";

const BulkAddField = (props) => {
  const separator = "!!!SpecialSeparator!!!";
  const separateStringToArray =
    props.prefilledValues != null && props.prefilledValues !== ""
      ? props.prefilledValues.split(separator)
      : "";
  const arrayToObjects = separateStringToArray
    ? separateStringToArray.map((v, index) => {
        return { id: index.toString(), arrayValue: v };
      })
    : "";
  const [data, setData] = useState(arrayToObjects ? arrayToObjects : []);

  const updateTeamData = () => {
    const arrayToStringValue = data.map((d) => d.arrayValue).join(separator);
    props.setTeamData((teamData) => ({
      ...teamData,
      [props.name]: arrayToStringValue,
    }));
  };

  useEffect(() => {
    updateTeamData();
  }, [data]);
  const renderRow = (index, data, onChange) => (
    <BulkAdd.Row key={data.id} index={index} rowData={{ id: data.id }}>
      <BulkAdd.RowItem textWrap="wrap">
        <Input
          label="Bulk Add"
          name="arrayValue"
          onChange={onChange}
          hideLabel
          value={data["arrayValue"] ? data["arrayValue"] : ""}
        ></Input>
      </BulkAdd.RowItem>
    </BulkAdd.Row>
  );

  const onRemoveRow = (index) => {
    setData(data.filter((value, dataIndex) => dataIndex !== index));
  };

  const onAddRow = (newData) => {
    setData(data.concat(newData));
  };

  const onRowChange = (index, name, value) => {
    if (value) {
      data[index][name] = value;
      setData([...data]);
    } else {
      onRemoveRow(index);
    }
  };

  return (
    <div>
      <Label tone="neutral">{props.title}</Label>
      <BulkAdd>
        <BulkAdd.Rows
          data={data}
          renderRow={renderRow}
          onRowChange={onRowChange}
          onRemoveRow={onRemoveRow}
          onAddRow={onAddRow}
        />
      </BulkAdd>
      <br />
    </div>
  );
};

export default BulkAddField;
