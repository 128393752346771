export const Team = {
  teamName: null,
  currentlyInRotation: null,
  hiringStatus: null,
  hiringStatusMessage: null,
  keyContacts: null,
  managers: null,
  protegeAlumni: null,
  rotationLength: null,
  teamDetails: null,
  techDetails: null,
  verticalId: null,
  waitList: null,
  lastEditTime: null,
};
