import {
  Button,
  Input,
  Modal,
  Select,
  Columns,
  RichTextEditor,
} from "@myob/myob-widgets";
import React, { useEffect, useState } from "react";
import { showError } from "./Message";
import { Team } from "../Types/TeamType";
import BulkAddField from "./BulkAddField";
import { HiringStatus } from "../Types/HiringStatus";
import { addTeam, updateTeam } from "../ApiService";

const TeamForm = (props) => {
  const team = props.team;

  const [teamData, setTeamData] = useState(team ? team : Team);
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.toEditorState(
      team && team.teamDetails ? team.teamDetails : "",
    ),
  );
  const handleRichChange = () => {
    setTeamData((teamData) => ({
      ...teamData,
      teamDetails: RichTextEditor.toHtml(editorValue),
    }));
  };
  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setTeamData((teamData) => ({
      ...teamData,
      [name]: value,
    }));
  };
  const checkRequiredField = () => {
    return (
      teamData.teamName &&
      teamData.verticalId &&
      !isNaN(teamData.hiringStatus) &&
      teamData.hiringStatus !== "placeholder" &&
      teamData.verticalId !== "placeholder"
    );
  };

  const [errorMessage, setErrorMessage] = useState("");
  const saveTeam = () => {
    teamData.verticalId = parseInt(teamData.verticalId);
    teamData.hiringStatus = parseInt(teamData.hiringStatus);
    if (JSON.stringify(team) === JSON.stringify(teamData)) {
      setErrorMessage("Please make a change");
    } else if (checkRequiredField()) {
      (props.operation === "Add"
        ? addTeam(teamData)
        : updateTeam(team.teamId, teamData)
      )
        .then((resp) => {
          if (resp.status === 201 || resp.status === 200) {
            const message =
              resp.status === 201
                ? "Team added successfully"
                : "Team details saved";
            props.setSuccessMessage(message);
            props.setShowTeamForm(false);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    } else {
      const error =
        (!teamData.teamName ? "Team name, " : "") +
        (!teamData.hiringStatus || teamData.hiringStatus === "placeholder"
          ? "Hiring status, "
          : "") +
        (!teamData.verticalId || teamData.verticalId === "placeholder"
          ? "Vertical"
          : "");
      setErrorMessage("These fields are missing: " + error);
    }
  };
  useEffect(() => {
    handleRichChange();
  }, [editorValue]);
  const footer = (
    <Modal.Footer>
      <Button
        type="secondary"
        className="btn btn-default"
        onClick={() => props.setShowTeamForm(false)}
      >
        Cancel
      </Button>
      <Button className="btn btn-primary" onClick={() => saveTeam()}>
        {props.operation === "Add" ? "Add" : "Save"}
      </Button>
    </Modal.Footer>
  );
  const body = (
    <Modal.Body>
      <Input
        type="text"
        name="teamName"
        onChange={handleDataChange}
        label="Team name"
        requiredLabel="This field is required"
        defaultValue={team ? team.teamName : ""}
      />
      <Select
        name="verticalId"
        label="Vertical"
        defaultValue={team ? team.verticalId.toString() : "placeholder"}
        requiredLabel="This field is required"
        onChange={handleDataChange}
      >
        <Select.Option value="placeholder" label="Please select a Vertical" />
        {props.verticals.map((vertical) => (
          <Select.Option
            value={vertical.verticalId.toString()}
            label={vertical.verticalName}
            key={vertical.verticalId}
          />
        ))}
      </Select>
      <Columns>
        <BulkAddField
          name={"managers"}
          setTeamData={setTeamData}
          title={"Managers"}
          prefilledValues={team ? team.managers : ""}
        />
        <BulkAddField
          name={"keyContacts"}
          setTeamData={setTeamData}
          title={"Key Contacts"}
          prefilledValues={team ? team.keyContacts : ""}
        />
      </Columns>
      <Columns>
        <BulkAddField
          name={"protegeAlumni"}
          setTeamData={setTeamData}
          title={"Protege Alumni"}
          prefilledValues={team ? team.protegeAlumni : ""}
        />
        <BulkAddField
          name={"techDetails"}
          setTeamData={setTeamData}
          title={"Tech Details"}
          prefilledValues={team ? team.techDetails : ""}
        />
      </Columns>

      <RichTextEditor
        onChange={setEditorValue}
        value={editorValue || ""}
        //value={teamData.teamDetails || ""}
        name="teamDetails"
        label="Team Details"
        hideLabel={false}
      />

      <Select
        name="hiringStatus"
        label="Hiring Status"
        defaultValue={team ? team.hiringStatus.toString() : "placeholder"}
        requiredLabel="This field is required"
        onChange={handleDataChange}
      >
        <Select.Option value="placeholder" label="Please select a status" />
        {Object.keys(HiringStatus).map((status, value) => (
          <Select.Option value={value.toString()} label={status} key={value} />
        ))}
      </Select>
      <Input
        type="text"
        name="hiringStatusMessage"
        onChange={handleDataChange}
        label="Hiring Status Message"
        defaultValue={team ? team.hiringStatusMessage : ""}
      />
      <Input
        type="text"
        name="rotationLength"
        onChange={handleDataChange}
        label="Rotation Length"
        defaultValue={team ? team.rotationLength : ""}
      />
      <BulkAddField
        name={"currentlyInRotation"}
        setTeamData={setTeamData}
        title={"Currently In Rotation"}
        prefilledValues={team ? team.currentlyInRotation : ""}
      />
      <BulkAddField
        name={"waitList"}
        setTeamData={setTeamData}
        title={"Wait List"}
        prefilledValues={team ? team.waitList : ""}
      />
      {errorMessage && showError(errorMessage)}
    </Modal.Body>
  );

  return (
    <Modal
      title={props.operation === "Add" ? "Add a Team" : "Edit Team"}
      onCancel={() => props.setShowTeamForm(false)}
    >
      {body}
      {footer}
    </Modal>
  );
};
export default TeamForm;
